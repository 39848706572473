import { Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useMemo, useState } from 'react';
import { useGetSearchConversationListQuery } from 'src/hooks/features/useAdminMessageManagement';
import { getCustomerConversationDetailSuccess } from 'src/store/admin/customer-conversation-detail/reducers';
import { IConversation, ISearchConversationListRequest } from 'src/types/admin/message-management';
import { useAppDisPatch } from 'src/utils/common';
import { ConversationForm } from './conversation-form';
import { IPaginationParams } from 'src/types/common';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

interface IRecord extends IConversation {
  key: React.Key;
}

const inittialParams: IPaginationParams = {
  page: 1,
  page_size: 10,
}

export const useAllConversations = () => {
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false);
  const dispatch = useAppDisPatch();
  const [editingKey, setEditingKey] = useState<React.Key>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
  const [searchPayload, setSearchPayload] = useState<
  {
    searchValue: ISearchConversationListRequest | null,
    params: IPaginationParams,
  }
  >({
    searchValue: null,
    params: inittialParams,
  });
  const [isOpenDetailConversation, setIsOpenDetailConversation] = useState<boolean>(false);

  const { data: conversationListData, isLoading: conversationListLoading } = useGetSearchConversationListQuery(searchPayload);
  const total = conversationListData?.data?.metadata?.total_conversations || 0;

  const navigate = useNavigate();

  const columns: ColumnsType<IRecord> = [
    {
      title: 'Phone',
      key: 'phone',
      dataIndex: 'phone',
      width: '15%',
    },
    {
      title: 'Name',
      key: 'user_name',
      dataIndex: 'user_name',
      width: '15%',
    },
    {
      title: 'Messages',
      key: 'message',
      dataIndex: 'message',
      width: '25%',
      render: (_, record: IConversation) => {
        return (
         <>{record?.messages?.length && record?.messages[0]?.content}</>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: '35%',
      render: (_, record: IConversation) => {
        return (
          <div className='flex gap-8'>
            <Button
              className='bg-main-theme font-semibold shadow-none'
              onClick={() => {
                navigate(`/admin/inbox?phone=${encodeURIComponent(record?.phone)}&name=${encodeURIComponent(record?.user_name)}`);
              }}
              type='primary'
            >
              Chat
            </Button>
            <Button
              className='bg-main-theme font-semibold shadow-none'
              onClick={() => {
                dispatch(getCustomerConversationDetailSuccess(record));
                setIsOpenDetailConversation(true);
                // navigate(`/admin/message-management/${record?.user_name}`)
              }}
              type='primary'
            >Detail Conversation</Button>
          </div>
        
        )
      }
    },
    {
      title: 'Session',
      key: 'session',
      dataIndex: 'session',
      width: '10%',
      render: (v: string) => (
        <Tooltip title={v}>
          <Button
            type='primary'
            className='!bg-[#6d32a8] !text-white font-semibold shadow-none'
            onClick={() =>  {
              navigator.clipboard.writeText(v);
              toast.success('Copy session to clipboard.');
            }}
          >
            <FontAwesomeIcon icon={faCode} />
          </Button>
        </Tooltip>
      )
    },
  ]

  const buttons: any = []

  const dataSource = useMemo(() => {
    return conversationListData?.data?.data?.values?.map((item) => ({
      ...item,
      key: item.session,
    }))
  }, [conversationListData]);

  const expandedRowRender = (record: IRecord) => {
    if (editingKey === record.key) {
      return <ConversationForm initialValues={record} />;
    }
    return null;
  };


  // const handleChangeParams = (params: IPaginationParams) => {
  //   setParams((prev) => ({ ...prev, ...params }));
  // };

  const onChangePage = (page: number) => {
    setSearchPayload((prev) => ({ ...prev, params: {
      ...prev.params,
      page: page,
    } }));
  };

  const onSearch = (searchValue: ISearchConversationListRequest | null) => {
    setSearchPayload((prev) => (
      {
        searchValue: searchValue,
        params: {
          ...prev.params,
          page: inittialParams.page,
        }
      }
    ));
  }


  return {
    columns,
    buttons,
    isOpenConfirmDelete,
    dataSource,
    conversationListLoading,
    editingKey,
    expandedRowKeys,
    searchPayload,
    currentPage: searchPayload.params.page || 1,
    pageSize: inittialParams.page_size,
    total,
    isOpenDetailConversation,
    onSearch,
    setIsOpenDetailConversation,
    setSearchPayload,
    expandedRowRender,
    setEditingKey,
    setExpandedRowKeys,
    setIsOpenConfirmDelete,
    onChangePage,
  }
}