import { IGetUnseenMessageRequest, IMessageHistoryRequest } from "src/types/admin/inbox";
import useAppQuery from "../useAppQuery";
import { getMessageHistory, getUnseenMessage, markUnseenMessage, sendWhatsappMessage } from "src/api/adminInbox";
import useAppMutation, { AppMutationOptions } from "../useAppMutation";

export const useGetWhatsappMessageHistory  = (request: IMessageHistoryRequest) =>
  useAppQuery({
    queryKey: ['admin-whatsapp-message-history', request],
    queryFn: () => getMessageHistory(request),
    enabled: !!request?.body?.phone_number,
  })


export const useSendWhatsappMessageMutation = (options?: AppMutationOptions) =>
  useAppMutation(sendWhatsappMessage, {
    useAppMutationProps: options,
  })

export const useGetUnseenMessageQuery  = (request: IGetUnseenMessageRequest) =>
  useAppQuery({
    queryKey: ['admin-get-unseen-message', request],
    queryFn: () => getUnseenMessage(request),
    enabled: !!request?.user_phones?.length,
  })

export const useMarkUnseenMessageMutation = (options?: AppMutationOptions) =>
  useAppMutation(markUnseenMessage, {
    useAppMutationProps: options,
  })

