import { EventSource } from 'eventsource';
import { useEffect, useMemo } from 'react';
import { getAdminSession } from 'src/utils/admin-session';
import { useGetUnseenMessageQuery } from './features/useInbox';
import { useAppDisPatch, useAppSelector } from 'src/utils/common';
import { setAdminInboxNotification, updateAdminInboxNotification } from 'src/store/admin/inbox-notification/reducers';
import { toast } from 'react-toastify';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const CustomToast = ({ closeToast, phone_number, name, session }: { closeToast?: () => void; phone_number: string; name: string; session?: string | null }) => {
  const navigate = useNavigate();

  const navigateToChat = () => {
    navigate(`/admin/inbox?phone=${encodeURIComponent(phone_number)}&name=${encodeURIComponent(name)}${session ? `&session=${encodeURIComponent(session)}` : ''}`);
    closeToast && closeToast();
  }
  
  return (
    <div className='text-dark-gray cursor-pointer' onClick={navigateToChat}>
      <div>
        <FontAwesomeIcon icon={faCommentDots} className='text-[24px] text-pink-theme-1' />
        <span className='text-[20px] ml-2 text-pink-theme-1'>
          Inbox Noti
        </span>
      </div>
      <div className='mt-2 pt-1 pb-1'>
        <p>New message from: {name} ({phone_number})</p>
      </div>
    </div>
  )
};


export const useAdminNotificate = () => {
  const { data: conversationsData } = useAppSelector((state) => state.adminInboxConversations);
  const dispatch = useAppDisPatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const pathName = location.pathname;
  const phone = searchParams.get('phone');
  

  const user_phones = useMemo(() => {
    return conversationsData?.map((item) => item.phone) ?? [];
  }, [conversationsData])
  const { data: unseenData } = useGetUnseenMessageQuery({ user_phones: user_phones });

  useEffect(() => {
    const payload = unseenData?.data;
    if (payload?.length)
      dispatch(setAdminInboxNotification(payload));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unseenData]);

  useEffect(() => {
    const session = getAdminSession();

    if (session?.access_token) {
      const es = new EventSource(`${process.env.REACT_APP_BACK_END_BASE_URL}admins/inbox-events/new-message`, {
        fetch: (input, init) =>
          fetch(input, {
            ...init,
            headers: {
              ...init?.headers,
              'x-access-token': session?.access_token,
            },
          }),
      });
  
      es.addEventListener('message', (event) => {
        try {
          const data: { user_phone: string } = JSON.parse(event.data);
          if (data?.user_phone){
            dispatch(updateAdminInboxNotification(data.user_phone));
          }

          // push notification;
          const foundUserPhone = conversationsData?.find((item) => item.phone === data.user_phone);

          if (foundUserPhone && !(pathName === '/admin/inbox' && phone === foundUserPhone.phone)) {
            toast(<CustomToast phone_number={foundUserPhone.phone} name={foundUserPhone.name} session={foundUserPhone.session} />, {
              position: 'bottom-left',
              className: 'shadow-primary',
              autoClose: 5000,
              theme: 'dark'
            })
          }

        } catch(err) {
          console.log('Error when receive new message event (notification)')
        }
      })
      return () => {
        es.close();
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName, phone]);
}