import React, { useMemo } from "react";
import { useAppSelector } from "src/utils/common";

interface IProps {
  phoneNumber: string;
}

export const UnseenBadge: React.FC<IProps> = ({ phoneNumber }) => {
  const { data: unseenData } = useAppSelector((state) => state.adminInboxNotifications);

  const countUnseen = useMemo(() => {
    const found = unseenData?.find((item) => item.user_phone === phoneNumber);
    if (found) {
      return found?.unseen_count;
    }

    return 0;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unseenData])

  return (
    <>
      {
        countUnseen ? (
          <div className="absolute h-[26px] w-[26px] rounded-full flex justify-center font-bold right-[-8px] top-[-8px] bg-main-theme z-50">
            <span className="leading-[12px] text-white mt-[6px] text-[16px]">
              {countUnseen > 9 ? '+9' : countUnseen}
            </span>
          </div>
        ) : null
      }
    </>
  )
}