import { createSlice } from '@reduxjs/toolkit';
import { IAdminInboxNotificationStore } from 'src/types/admin/inbox';

const initialState: IAdminInboxNotificationStore = {
  data: null,
};

const sessionSlice = createSlice({
  name: 'adminInboxNotification',
  initialState: initialState,
  reducers: {
    setAdminInboxNotification: (state, action?) => {
      state.data = action?.payload;
    },
    markUnseenAdminInboxNotification: (state, action?) => {
      state.data = state?.data?.map((item) => {
        if (item.user_phone === action?.payload) {
          return {
            user_phone: item.user_phone,
            unseen_count: 0,
          }
        }
        return item;
      }) ?? [];
    },
    updateAdminInboxNotification: (state, action?) => {
      state.data = state?.data?.map((item) => {
        if (item.user_phone === action?.payload) {
          return {
            user_phone: item.user_phone,
            unseen_count: item?.unseen_count + 1,
          }
        }
        return item;
      }) ?? [];
    },
    resetAdminInboxNotification: (state) => {
      state.data = null;
    },
  },
});

export const { resetAdminInboxNotification, setAdminInboxNotification, updateAdminInboxNotification, markUnseenAdminInboxNotification } =
  sessionSlice.actions;

export default sessionSlice.reducer;