import React from "react";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css'

type Props = {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
}

const PInput: React.FC<Props> = ({ value, onChange, placeholder, disabled = false, className='' }) => {

  return (
    <div className={`ant-input-wrapper h-input-sm sm:h-input-sm md:h-input relative ${ disabled ? 'custom-phone-input-disabled' : 'custom-phone-input' }`}>
      <PhoneInput
        containerClass={`!w-full !h-full`}
        inputClass={`!w-full !h-full ant-input-wrapper ${className}`}
        value={value}
        onChange={(a) => {
          onChange && onChange(a);
        }}
        disabled={disabled}
        placeholder={''}
        country={'sg'}
      />
    </div>
    
  )
};

export default PInput;
