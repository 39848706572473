/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import { CHECK_DOB, CHECK_ID_EXPIRY, CHECK_PHONE_NUMBER, REQUIRED, CHECK_EMAIL } from "../../utils/rule-form";
import { parseAtlasData, useAppDisPatch, useAppSelector } from "../../utils/common";
import { useSelector } from "react-redux";
import { RootState } from "../../types/store";
import { signUp } from "../../store/auth/sign-up/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import PInput from "../../components/form/phone-input";
import { fetchCountryList } from "../../store/common/country-list/actions";
import { fetchIdType } from "../../store/common/id-type/actions";
import dayjs from "dayjs";
import { ISignUpAccount } from "../../types/user";
import { BaseInput } from "../../components/form/base-input";
import { BaseSelect } from "../../components/form/base-select";
import { BaseDatePicker } from "../../components/form/base-date-picker";
import FormSectionLayout from "../../components/layout/form-section";
import { fetchOccupationList } from "../../store/common/occupation-list/actions";
import { fetchSourceOfFundList } from "../../store/common/source-of-fund-list/actions";
import { resetSignUpData } from "../../store/auth/sign-up/reducers";
import { getToken } from "../../store/auth/get-token/actions";
import SingPassButton from './../../assets/svg/neutral.svg'
import AxiosUtils from "./../../utils/axios-utils";
import { useSearchParams } from "react-router-dom";
import { getSampleDataSuccess } from "./../../store/singpass/get-sample-data/reducers";
import { fetchLoginUrl } from "../../store/singpass/get-login-url/actions";
import { toast } from "react-toastify";
import Logo from "../../components/layout/logo";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import CryptoJS from 'crypto-js';

const SignUpPage = () => {
  const dispatch = useAppDisPatch();
  const [form] = Form.useForm();
  const signUpStore = useSelector((state: RootState) => state.signUp);
  const getTokenStore = useSelector((state: RootState) => state.getToken);
  const idTypeList = useSelector((state: RootState) => state.idType);
  const countryList = useSelector((state: RootState) => state.countryList);
  const sourceOfFundList = useSelector((state: RootState) => state.sourceOfFundList);
  const occupationList = useSelector((state: RootState) => state.occupationList);
  const { data: singpassData } = useSelector((state: RootState) => state.singpassMockData);
  const [disabledFields, setDisabledFields] = useState<string[]>([]);
  const [searchParams] = useSearchParams();
  const loginUrl = useAppSelector((state: RootState) => state.loginUrl);

  useEffect(() => {
    if (singpassData) {
      const rawData = {
        ...singpassData,
        date_of_birth: singpassData.date_of_birth ? dayjs(singpassData.date_of_birth) : null,
        id_expiry_date: singpassData.id_expiry_date ? dayjs(singpassData.id_expiry_date) : null,
      }
      form.setFieldsValue(rawData);
      const keys = Object.keys(rawData);
      const dataFieldNames = keys.filter((item: string) => {
        if ((rawData as any)[item]) {
          return (rawData as any)[item];
        }
      })
      setDisabledFields(dataFieldNames)
    }
  }, [singpassData])

  const onSignUp = (formValues: ISignUpAccount) => {
    const rawPhoneNumber = formValues.phone_number?.includes('+') ? formValues.phone_number : `+${formValues.phone_number}`;
    const phoneNumber = rawPhoneNumber ? parsePhoneNumberFromString(rawPhoneNumber) : null;

    const dialCode = phoneNumber?.countryCallingCode;
    const nationalNumber = phoneNumber?.nationalNumber;
    
    dispatch(signUp({
      id_type: formValues.id_type,
      id_number: formValues.id_number,
      first_name: formValues.first_name,
      last_name: formValues.last_name,
      name_in_2nd_language: formValues.name_in_2nd_language,
      nationality: formValues.nationality,
      country_of_birth: formValues.country_of_birth,
      gender: formValues.gender,
      email: formValues.email,
      address: formValues.address,
      country: formValues.country,
      occupation: formValues.occupation,
      source_of_fund: formValues.source_of_fund,
      phone_number: nationalNumber,
      phone_code: `+${dialCode}`,
      date_of_birth: dayjs(formValues.date_of_birth).format('YYYY-MM-DD') ?? '',
      ...(formValues.id_expiry_date ? { id_expiry_date: dayjs(formValues.id_expiry_date).format('YYYY-MM-DD') } : {})
    }));
  }

  useEffect(() => {
    if (getTokenStore.isCalled) {
      dispatch(fetchCountryList());
      dispatch(fetchIdType());
      dispatch(fetchOccupationList());  
      dispatch(fetchSourceOfFundList());
    }
  }, [getTokenStore.isCalled]);

  useEffect(() => {
    dispatch(getToken({}))
  }, [])

  useEffect(() => {
    if (signUpStore.isSuccess === true) {
      dispatch(resetSignUpData({}))
      window.location.replace(signUpStore.data.message);
    } 
  }, [signUpStore.isSuccess])

  const handleFetchUrl = async () => {
    dispatch(fetchLoginUrl());
  }

  useEffect(() => {
    if (loginUrl.data) {
      localStorage.setItem('codeVerifier', loginUrl.data.codeVerifier);
      window.location.href = loginUrl.data.authorizeUrl;
    } 
  }, [loginUrl.data])

  useEffect(() => {
    if (searchParams.get('code')) {
      AxiosUtils.post(`${process.env.REACT_APP_BE_UI_BASE_URL}getPersonData`, {
        authCode: searchParams.get('code'),
        codeVerifier: localStorage.getItem('codeVerifier')
      }, {
        'Content-Type': 'application/json'
      }).then((response: any) => {
        const parsedData = parseAtlasData(response.data);
        dispatch(getSampleDataSuccess(parsedData));
      }).catch(() => {
        toast.error('Singpass unavailable')
      })
    }
  }, [])

  useEffect(() => {
    try {
      const encrypted = searchParams.get('id')?.replaceAll(' ', '+');
      const key = process.env.REACT_APP_SECURE_CODE?.substring(0, 16);
      if (encrypted && key) {
          const encryptedKey = CryptoJS.enc.Utf8.parse(key);
          const decrypted = CryptoJS.AES.decrypt(encrypted, encryptedKey, { mode: CryptoJS.mode.ECB, });
          const phone_number = decrypted.toString(CryptoJS.enc.Utf8);
          form.setFieldsValue({ phone_number: phone_number });
          setDisabledFields(['phone_number']);
      }
    } catch(err) {
      toast.error('Failed to decrypt id')
    }
    
  }, [])

  return (
    <div className="w-full h-screen gradient-theme flex overflow-y-auto flex-col">
      <Form
        onFinish={onSignUp}
        form={form}
        layout='vertical'
        className="mx-auto max-w-[900px] w-[100%] max-lg:max-w-[auto] px-4 mb-16"
        >

          <div className="flex justify-between max-lg:flex-col xl:flex-row">
            <div className="text-4xl max-lg:text-3xl max-sm:tex-2xl font-semibold mt-5 font-rale-way max-lg:text-center xl:text-left">
              <Logo fontSize="text-[28px]" />
              <span className="ml-2 text-[28px]">Registration Form</span>
            </div>
            <div className="flex justify-center mt-5">
              <Button
                  type='default'
                  className="h-auto rounded-[12px] bg-white text-white font-bold p-0 border-none"
                  onClick={() => handleFetchUrl()}
              >
              <img src={SingPassButton} alt='singpass' />
              </Button>
            </div>
          </div>

          <FormSectionLayout sectionTitle="Personal Information" className="mt-8">
            <div className="flex gap-3 justify-between max-sm:flex-col">
              <div className="w-[100%]">
                <Form.Item name='id_type' label='ID type' className="mb-2 sm:mb-2 md:mb-6" rules={[REQUIRED]}>
                  <BaseSelect
                    className="ant-input-wrapper h-input-sm sm:h-input-sm md:h-input"
                    showSearch
                    style={{ width: '100%' }}
                    options={idTypeList.data}
                    loading={idTypeList.isLoading}
                    disabled={!!disabledFields.find((item) => 'id_type' === item)}
                  />
                </Form.Item>
                <Form.Item name='id_number' label='ID Number' className="mb-2 sm:mb-2 md:mb-6" rules={[REQUIRED]}>
                  <BaseInput className="ant-input-wrapper h-input-sm sm:h-input-sm md:h-input"
                    disabled={!!disabledFields.find((item) => 'id_number' === item)}
                  />
                </Form.Item>
                <Form.Item name='id_expiry_date' label='ID Expiry Date' className="mb-2 sm:mb-2 md:mb-6" rules={[CHECK_ID_EXPIRY]}>
                  <BaseDatePicker disabled={!!disabledFields.find((item) => 'id_expiry_date' === item)} placeholder='' className="ant-input-wrapper h-input-sm sm:h-input-sm md:h-input" format='DD/MM/YYYY' />
                </Form.Item>
                <Form.Item name='country_of_birth' label='Country of Birth' className="mb-2 sm:mb-2 md:mb-6" rules={[REQUIRED]}>
                  <BaseSelect
                    className="ant-input-wrapper h-input-sm sm:h-input-sm md:h-input"
                    showSearch
                    style={{ width: '100%' }}
                    options={countryList.data}
                    loading={countryList.isLoading}
                    disabled={!!disabledFields.find((item) => 'country_of_birth' === item)}
                  />
                </Form.Item>
                <Form.Item name='first_name' label='First Name' className="mb-2 sm:mb-2 md:mb-6" rules={[REQUIRED]}>
                  <BaseInput disabled={!!disabledFields.find((item) => 'first_name' === item)} className="ant-input-wrapper h-input-sm sm:h-input-sm md:h-input"/>
                </Form.Item>
                <Form.Item name='gender' label='Gender' className="mb-2 sm:mb-2 md:mb-6" rules={[REQUIRED]}>
                  <BaseSelect
                    className="ant-input-wrapper h-input-sm sm:h-input-sm md:h-input"
                    showSearch
                    style={{ width: '100%' }}
                    options={[
                      {
                        value: 'FEMALE',
                        label: 'Female'
                      },
                      {
                        value: 'MALE',
                        label: 'Male'
                      }
                    ]}
                    loading={countryList.isLoading}
                    disabled={!!disabledFields.find((item) => 'gender' === item)}
                  />
                </Form.Item>
                
              </div>
              <div className="w-[100%]">
                <Form.Item name='name_in_2nd_language' label='Name In 2nd Language' className="mb-2 sm:mb-2 md:mb-6" rules={[]}>
                  <BaseInput className="ant-input-wrapper h-input-sm sm:h-input-sm md:h-input"
                    disabled={!!disabledFields.find((item) => 'name_in_2nd_language' === item)}
                  />
                </Form.Item>
                <Form.Item name='date_of_birth' label='Date Of Birth' className="mb-2 sm:mb-2 md:mb-6" rules={[REQUIRED, CHECK_DOB]}>
                  <BaseDatePicker placeholder='' className="ant-input-wrapper h-input-sm sm:h-input-sm md:h-input" format='DD/MM/YYYY'
                    disabled={!!disabledFields.find((item) => 'date_of_birth' === item)}
                  />
                </Form.Item>
                <Form.Item name='nationality' label='Nationality' className="mb-2 sm:mb-2 md:mb-6" rules={[REQUIRED]}>
                  <BaseSelect
                    className="ant-input-wrapper h-input-sm sm:h-input-sm md:h-input"
                    showSearch
                    style={{ width: '100%' }}
                    options={countryList.data}
                    loading={countryList.isLoading}
                    disabled={!!disabledFields.find((item) => 'nationality' === item)}
                  />
                </Form.Item>
                <Form.Item name='full_name' label='Full Name' className="mb-2 sm:mb-2 md:mb-6">
                  <BaseInput className="ant-input-wrapper h-input-sm sm:h-input-sm md:h-input"
                    disabled={!!disabledFields.find((item) => 'full_name' === item)}
                  />
                </Form.Item>
                <Form.Item name='last_name' label='Last Name' className="mb-2 sm:mb-2 md:mb-6" rules={[REQUIRED]}>
                  <BaseInput className="ant-input-wrapper h-input-sm sm:h-input-sm md:h-input"
                    disabled={!!disabledFields.find((item) => 'last_name' === item)}
                  />
                </Form.Item>
                <Form.Item name='residential_status' label='Residential Status' className="mb-2 sm:mb-2 md:mb-6" rules={[REQUIRED]}>
                  <BaseSelect 
                    className="ant-input-wrapper h-input-sm sm:h-input-sm md:h-input"
                    showSearch
                    style={{ width: '100%' }}
                    options={[
                      {
                        value: 'CITIZEN',
                        label: 'Citizen'
                      },
                      {
                        value: 'PR',
                        label: 'PR'
                      },
                      {
                        value: 'OTHERS',
                        label: 'Others'
                      }
                    ]}
                    disabled={!!disabledFields.find((item) => 'residential_status' === item)}
                  />
                </Form.Item>
              </div>
            </div>
          </FormSectionLayout>
          <div className="flex gap-3 mt-3 max-sm:flex-col">
            <FormSectionLayout sectionTitle="Contact Information" className="flex-1">
              <div className="w-[100%]">
                <Form.Item name='phone_number' label='Phone Number' className="mb-2 sm:mb-2 md:mb-6" rules={[REQUIRED, CHECK_PHONE_NUMBER]}
                >
                  <PInput 
                    disabled={!!disabledFields.find((item)  =>'phone_number' === item)}
                    className="!border-[white]"
                  />
                </Form.Item>
                <Form.Item name='email' label='Email' className="mb-2 sm:mb-2 md:mb-6" rules={[CHECK_EMAIL]}>
                  <BaseInput className="ant-input-wrapper h-input-sm sm:h-input-sm md:h-input"
                    disabled={!!disabledFields.find((item) => 'email' === item)}
                  />
                </Form.Item>
                <Form.Item name='address' label='Address (as in NRIC)' className="mb-2 sm:mb-2 md:mb-6" rules={[REQUIRED]}>
                  <BaseInput className="ant-input-wrapper h-input-sm sm:h-input-sm md:h-input"
                    disabled={!!disabledFields.find((item) => 'address' === item)}
                  />
                </Form.Item>
              </div>
            </FormSectionLayout>
            <FormSectionLayout sectionTitle="Compliance Information" className="flex-1">
              <div className="w-[100%]">
                <Form.Item name='country' label='Country Of Residence' className="mb-2 sm:mb-2 md:mb-6" rules={[REQUIRED]}>
                  <BaseSelect
                    className="ant-input-wrapper h-input-sm sm:h-input-sm md:h-input"
                    showSearch
                    style={{ width: '100%' }}
                    options={countryList.data}
                    loading={countryList.isLoading}
                    disabled={!!disabledFields.find((item) => 'country' === item)}
                  />
                </Form.Item>
                <Form.Item name='occupation' label='Occupation' className="mb-2 sm:mb-2 md:mb-6" rules={[REQUIRED]}>
                  <BaseSelect
                    className="ant-input-wrapper h-input-sm sm:h-input-sm md:h-input"
                    showSearch
                    style={{ width: '100%' }}
                    options={occupationList.data}
                    loading={occupationList.isLoading}
                    disabled={!!disabledFields.find((item) => 'occupation' === item)}
                  />
                </Form.Item>
                <Form.Item name='source_of_fund' label='Source Of Funds' className="mb-2 sm:mb-2 md:mb-6" rules={[REQUIRED]}>
                  <BaseSelect
                    className="ant-input-wrapper h-input-sm sm:h-input-sm md:h-input"
                    showSearch
                    style={{ width: '100%' }}
                    options={sourceOfFundList.data}
                    loading={sourceOfFundList.isLoading}
                    disabled={!!disabledFields.find((item) => 'source_of_fund' === item)}
                  />
                </Form.Item>
              </div>
            </FormSectionLayout>
          </div>
          {
            signUpStore?.error  && (
              <div className="text-center mt-3">
                <p className="text-pink-theme-1 text-md sm:text-md md:text-base">
                  <FontAwesomeIcon icon={faCircleExclamation} />&nbsp;
                  {
                    signUpStore?.error
                  }
                </p>
              </div>
            )
          }
          <div className="mb-3 sm:mb-3 md:mb-6" />
          <div className="flex justify-end gap-2 max-sm:flex-col flex-row mb-10 mt-6">
            <Button loading={signUpStore.isLoading} type='primary' htmlType="submit" className="text-lg h-10 rounded-[12px] bg-main-theme text-white font-bold">Next</Button>
          </div>
      </Form>
    </div>
  )
};

export default SignUpPage;