import { IGetLoginUrlResponse } from 'src/types/singpass';
import { createSlice } from '@reduxjs/toolkit';

interface IState {
  data: IGetLoginUrlResponse | null;
  isLoading: boolean;
  error: string;
}

const initialState: IState = {
  data: null,
  isLoading: false,
  error: '',
};

const sessionSlice = createSlice({
  name: 'get-login-url',
  initialState: initialState,
  reducers: {
    getLoginUrlLoading: (state) => {
      state.isLoading = true;
    },
    getLoginUrlSuccess: (state, action?) => {
      state.data = action?.payload;
      state.isLoading = false;
    },
    getLoginUrlFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetLoginUrl: (state) => {
      state.data = null;
      state.error = '';
      state.isLoading = false;
    }
  },
});

export const { getLoginUrlFailure, getLoginUrlLoading, getLoginUrlSuccess, resetLoginUrl } =
  sessionSlice.actions;

export default sessionSlice.reducer;