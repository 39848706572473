import { Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetSupportNeededConversationListQuery, useResolveConversationMutation } from 'src/hooks/features/useAdminMessageManagement';
import { getCustomerConversationDetailSuccess } from 'src/store/admin/customer-conversation-detail/reducers';
import { IConversation } from 'src/types/admin/message-management';
import { useAppDisPatch } from 'src/utils/common';
import { ConversationForm } from 'src/pages/admin/message-management/all-conversations/conversation-form';
import { IPaginationParams } from 'src/types/common';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';

const inittialParams: IPaginationParams = {
  page: 1,
  page_size: 10,
}
interface IRecord extends IConversation {
  key: React.Key;
}

export const useAllConversations = () => {
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false);
  const dispatch = useAppDisPatch();
  const navigate = useNavigate();
  const [editingKey, setEditingKey] = useState<React.Key>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
  const [isOpenDetailConversation, setIsOpenDetailConversation] = useState<boolean>(false);
  const [params, setParams] = useState<IPaginationParams>(inittialParams);

  const { data: conversationListData, isLoading: conversationListLoading } = useGetSupportNeededConversationListQuery(params);
  const { mutate: resolveConversationMutate } = useResolveConversationMutation();
  const total = conversationListData?.data?.metadata?.total_conversations || 0;
  const queryClient = useQueryClient();

  const onResolveConversation = (session: string) => {
    try {
      resolveConversationMutate( {
        session_id: session
      }, {
        onSuccess() {
          toast.success('Resolve conversation successfully!');
          queryClient.invalidateQueries({ queryKey: ['support_needed_conversation-list'] });
        },
        onError() {
          toast.error('Resolve failed!')
        },
      })
    } catch(err) {
      toast.error('Resolve failed!')
    }
  }

  const columns: ColumnsType<IRecord> = [
    {
      title: 'Phone',
      key: 'phone',
      dataIndex: 'phone',
      width: '10%',
    },
    {
      title: 'Name',
      key: 'user_name',
      dataIndex: 'user_name',
      width: '10%',
    },
    {
      title: 'Messages',
      key: 'message',
      dataIndex: 'message',
      width: '20%',
      render: (_, record: IConversation) => {
        return (
         <>{record?.messages?.length && record?.messages[0]?.content}</>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: '25%',
      render: (_, record: IConversation) => {
        return (
          <div className='flex gap-8'>
            <Button
              className='bg-main-theme font-semibold shadow-none'
              onClick={() => {
                navigate(`/admin/inbox?phone=${encodeURIComponent(record?.phone)}&name=${encodeURIComponent(record?.user_name)}&session=${encodeURIComponent(record?.session)}&supported=true`);
              }}
              type='primary'
            >
              Chat
            </Button>
            <Button
              className='bg-main-theme font-semibold shadow-none'
              onClick={() => {
                dispatch(getCustomerConversationDetailSuccess(record));
                setIsOpenDetailConversation(true);
              }}
              type='primary'
            >
              Detail Conversation
            </Button>
          </div>
        )
      }
    },
    {
      title: 'Resolve',
      dataIndex: 'resolve',
      key: 'resolve',
      width: '15%',
      render: (_, record) => {
        return (
          <Button className='text-pink-theme-1 border-pink-theme-1' onClick={() => onResolveConversation(record?.session)}>Resolve</Button>
        )
      }
    },
    {
      title: 'Session',
      key: 'session',
      dataIndex: 'session',
      width: '20%',
      render: (v: string) => (
        <Tooltip title={v}>
          <Button 
            type='primary'
            className='!bg-[#6d32a8] !text-white font-semibold shadow-none'
            onClick={() =>  {
              navigator.clipboard.writeText(v);
              toast.success('Copy session to clipboard.');
            }}
          >
            <FontAwesomeIcon icon={faCode} />
          </Button>
        </Tooltip>
      )
    },
  ]

  const buttons: any = []

  const dataSource = useMemo(() => {
    return conversationListData?.data?.data?.values?.map((item) => ({
      ...item,
      key: item.session,
    }))
  }, [conversationListData]);

  const expandedRowRender = (record: IRecord) => {
    if (editingKey === record.key) {
      return <ConversationForm initialValues={record} />;
    }
    return null;
  };

  const onChangePage = (page: number) => {
    setParams((prev) => ({ ...prev, page: page }));
  };

  useEffect(() => {
    if (conversationListData?.data?.data?.values && conversationListData?.data?.data?.values?.length === 0) {
      setParams((prev) => ({ ...prev, page: (prev?.page && prev?.page > 1) ? prev?.page - 1 : 1 }))
    }
  }, [conversationListData])

  return {
    columns,
    buttons,
    isOpenConfirmDelete,
    dataSource,
    conversationListLoading,
    editingKey,
    expandedRowKeys,
    isOpenDetailConversation,
    currentPage: params.page || 1,
    pageSize: inittialParams.page_size,
    total,
    onChangePage,
    setIsOpenDetailConversation,
    expandedRowRender,
    setEditingKey,
    setExpandedRowKeys,
    setIsOpenConfirmDelete,
  }
}