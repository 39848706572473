import { createSlice } from '@reduxjs/toolkit';
import { IAdminInboxConversationsStore } from 'src/types/admin/inbox';

const initialState: IAdminInboxConversationsStore = {
  data: null,
  isLoading: false,
};

const sessionSlice = createSlice({
  name: 'adminInboxConversationStore',
  initialState: initialState,
  reducers: {
    getAdminInboxConversationsLoading: (state) => {
      state.isLoading = true;
    },
    getAdminInboxConversationsSuccess: (state, action?) => {
      state.data = action?.payload;
      state.isLoading = false;
    },
    addAdminInboxConversation: (state, action?) => {
      state.data = [action?.payload, ...(state.data ?? [])];
      state.isLoading = false;
    },
    updateAdminInboxConversation: (state, action?) => {
      state.data = state.data?.map((item) => {
        if (action?.payload?.phone === item.phone){
          return {
            phone: item.phone,
            name: item.name,
            session: action?.payload?.session,
            messages: item.messages,
          }
        }
        return item;
      }) ?? [];
    },
    getAdminInboxConversationsFailure: (state, action) => {
      state.isLoading = false;
    },
    resetCustomerConversationDetail: (state) => {
      state.data = null;
      state.isLoading = false;
    }
  },
});

export const { getAdminInboxConversationsLoading, getAdminInboxConversationsSuccess, getAdminInboxConversationsFailure, resetCustomerConversationDetail, addAdminInboxConversation, updateAdminInboxConversation } =
  sessionSlice.actions;

export default sessionSlice.reducer;