/* eslint-disable array-callback-return */
import React, { useRef, useState } from "react";
import Logo from "../../../components/layout/logo";
import { Button, Divider } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/store";
import { useAppDisPatch } from "../../../utils/common";
import { IMessageItem } from "../../../types/chat";
import { setMessages } from "../../../store/chat/messages/reducers";
import Conversation from "./conversation";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { setConversations } from "../../../store/chat/conversations/reducers";

type Props = {
  onAddNewChat: () => void;
  activeConver?: string | null;
  onChangeActiveConver: (id: string) => void;
  onDeleteConver: (id: string) => void;
}

const SideBar: React.FC<Props> = ({ onAddNewChat, activeConver, onChangeActiveConver, onDeleteConver }) => {
  const dispatch = useAppDisPatch();
  const conversationsStore = useSelector((state: RootState) => state.conversations);
  const [updatedTopic, setUpdatedTopic] = useState<string | null>(null);
  const scrollRef = useRef<any>()

  // let api: any;
  // const useMyCoolSensor = (value: any) => {
  //   api = value;
  // };

  const changeActiveConver = (id: string, messages: IMessageItem[]) => {
    onChangeActiveConver(id);
    dispatch(setMessages(messages))
  }

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(conversationsStore.data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, {
      ...reorderedItem,
      topic: updatedTopic ?? reorderedItem.topic,
    });
    setUpdatedTopic(null);
    dispatch(setConversations(items));
  };

  // const moveStepByStep = (drag: any, values: any, id: string, topic: string, sourceIndex: number) => {
  //   requestAnimationFrame(() => {
  //     const newPosition = values.shift();
  //     drag.move(newPosition);
  //     if (values.length) {
  //       moveStepByStep(drag, values, id, topic, sourceIndex);
  //     } else {
  //       drag.drop();
  //     }
  //   });
  // }

  const startDrag = function start(id: string, topic: string, sourceIndex: number) {
    setUpdatedTopic(topic);

    // const preDrag = api.tryGetLock(id);
    // if (!preDrag) {
    //   return;
    // }
    // let order = 0;
    // for (let i = 0; i < conversationsStore.data.length; i++) {
    //   if (conversationsStore.data[i].id === id) break;
    //   order += 1;
    // }
    // const start = { x: 0, y: 0 };
    // const end = { x: 0, y: -(36 * ( order )) };
    // const drag = preDrag.fluidLift(start);
    // const points = [];
    // for (let i = 0; i < 20; i++) {
    //   points.push({
    //     x: tweenFunctions.easeOutCirc(i, start.x, end.x, 20),
    //     y: tweenFunctions.easeOutCirc(i, start.y, end.y, 20)
    //   });
    // }
    // moveStepByStep(drag, points, id, topic, sourceIndex);
  };

  return (
    <div className="overflow-hidden font-roboto">
      <div className="w-full gradient-theme relative h-screen">
        <div className="px-4 pt-4">
          <div className="flex justify-center mb-2" >
            <Logo fontSize="text-2xl" />
          </div>
          <Button onClick={onAddNewChat} type='primary' className="w-full h-10 rounded-[12px] bg-main-theme text-white font-bold mt-2">New chat</Button>
          <Divider />
        </div>
      <div className="h-[calc(100%_-_240px)] overflow-y-auto ps-4 pe-4" ref={scrollRef}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
          {(provided) => (
            <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
              {
                conversationsStore.data.map((item, index) => {
                  if (item.id) {
                    return (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided) => (
                          <li key={index} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="mb-1">
                            <Conversation
                              activeConver={activeConver}
                              conversation={item}
                              onDeleteConver={onDeleteConver}
                              changeActiveConver={changeActiveConver}
                              key={index}
                              onUpdateTopic={(id, topic) => startDrag(id, topic, index)}
                            />
                          </li>
                        )}
                      </Draggable>
                    )
                  }
                })
              }
              {provided.placeholder}
            </ul>
          )}
          </Droppable>
        </DragDropContext>
      </div>
      {/* <div className="absolute bottom-6 left-0 w-full">
        <div className="px-4"> 
        <Dropdown
          dropdownRender={() => {
            return (
              <div className="bg-white p-2 rounded-md">
                <SettingModal onClick={() => {
                  setIsSettingOpen(false);
                }} />
                <Button className="p-2 h-auto border-none w-full text-left text-gray-700" type='text' onClick={onSignupAtlas}>
                  <FontAwesomeIcon icon={faPlus} className="me-2 text-base w-s-20" />
                  Signup Atlas
                </Button>
                <Divider className="my-1"/>
                <Button className="p-2 h-auto border-none w-full text-left text-gray-700" type='text' onClick={onLogout}>
                  <FontAwesomeIcon icon={faArrowRightFromBracket} className="me-2 text-base w-s-20" />
                  Logout
                </Button>
              </div>
            )
          }}
          onOpenChange={(open) => {
            setIsSettingOpen(open);
          }}
          open={isSettingOpen}
          placement="top"
          trigger={['click']}
          arrow
        >
          <Button loading={userDetailLoading} className={`h-auto !w-full border-none duration-500 ${isSettingOpen ? 'bg-white' : ''}`} onClick={() => setIsSettingOpen(!isSettingOpen)}>
            {
              !userDetailLoading ? (
                <div className=" flex items-center gap-2 " >
                  <div className="rounded-full bg-pink-400">
                    <div className="!w-s-40 !h-s-40 flex items-center justify-center">
                      <p className="text-white text-[14px] uppercase">
                        {userDetail?.name?.charAt(0)}
                      </p>
                    </div>
                  </div>
                  <p className={`text-base font-semi-bold duration-500 ${isSettingOpen ? 'text-pink-theme-1' : ''}`}>
                    {userDetail?.name}
                  </p>
                </ div>
              ): null
            }
          </Button>
        </Dropdown>
        </div>
      </div> */}
      </div>
    </div>
  )
};

export default SideBar;